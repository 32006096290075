<script>

import {currencyConfig} from '@utils/money'
import {debounce} from 'lodash'
import {mapGetters} from "vuex";

export default {
  name: 'finish-order-product-item',
  props: {
    order: {
      type: Object,
      default: () => null
    },
    product: {
      type: Object,
      default: () => null
    },
    products: {
      type: Array,
      default: () => ([])
    },
    generateNfce: {
      type: Boolean,
      default: false
    },
    generateNfse: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      localProduct: {}
    }
  },
  watch: {
    product: {
      handler(value) {
        if (value) {
          this.localProduct = {...this.product}
        }
      },
      immediate: true
    },
    'localProduct.coupon_id': function (value) {
      if (value) {
        this.localProduct.payment = {
          paid: true
        }
      } else {
        this.localProduct.payment = null
      }
      const productIndex = this.products.findIndex(item => item.id === this.localProduct.id)
      const localProducts = [...this.products]
      localProducts.splice(productIndex, 1, this.localProduct)
      this.$emit('update:products', localProducts)
    }
  },
  computed: {
    ...mapGetters(['tenant']),
    moneyMask() {
      return currencyConfig()
    },
    productNfseDisabled() {
      return this.localProduct.product.type === 'service' && this.generateNfse && !this.localProduct.product.nf_enabled
    },
    productNfceDisabled() {
      return this.localProduct.product.type === 'product' && this.generateNfce && !this.localProduct.product.nf_enabled
    },
  },
  methods: {
    localUpdateAmounts: debounce(function () {
      if (this.localProduct.final_price !== this.product.final_price || this.localProduct.discount !== this.product.discount) {
        this.$emit('updateProduct', this.localProduct)
      }
    }, 200)
  }
}
</script>

<template>
  <tr v-if="product.id">
    <td width="30%">
      <div>
        <div>
          <span v-if="localProduct.split" class="mr-2" title="Produto divido" key="splitProducts">
            <i class="fas fa-users"></i>
          </span>
          {{ localProduct.product.description }}
        </div>
        <div :title="localProduct.schedule.description"
             class="gripo-tag"
             v-if="localProduct.schedule">
          {{ localProduct.schedule.description }}
        </div>
        <el-select v-model="localProduct.coupon_id"
                   v-if="localProduct.product.type === 'service' && order.client.coupons && order.client.coupons.length > 0"
                   size="mini"
                   clearable
                   class="el-select-full mt-1"
                   popper-class="el-select-custom-item"
                   placeholder="Selecione um pacote de créditos"
                   empty-text="Nenhum registro encontrado">
          <el-option v-for="item in order.client.coupons"
                     :key="item.id"
                     :label="`Créditos: ${item.uses_limit - item.uses_current_count}`"
                     :value="item.id">
            <div class="fs-12">{{ item.start_datetime | dateTimeEnToBr }}h</div>
            <div class="fs-14 pt-1">
              <strong>Créditos Disponíveis:</strong> {{ item.uses_limit - item.uses_current_count }}
            </div>
          </el-option>
        </el-select>
        <el-alert type="info" class="mt-2 small-alert text-center" :closable="false"
                  v-if="productNfseDisabled || productNfceDisabled">
          <slot name="title">
            <div v-if="productNfseDisabled">
              <strong>Serviço não habilitado para NFSe</strong> (Verifique o cadastro do serviço)
            </div>
            <div v-if="productNfceDisabled">
              <strong>Produto não habilitado para NFCe</strong> (Verifique o cadastro do produto)
            </div>
          </slot>
        </el-alert>
      </div>
    </td>
    <td align="center" style="min-width: 70px; max-width: 70px;">
      {{ localProduct.quantity }} ({{ localProduct.product.unit }})
    </td>
    <template v-if="!localProduct.payment">
      <td align="right" style="min-width: 80px; max-width: 80px;">{{ localProduct.price | currencyFormatter }}</td>
      <td align="right" style="min-width: 130px;">
        <el-form-item class="mb-0">
          <input v-model.lazy="localProduct.discount"
                 v-money="moneyMask"
                 @input="localUpdateAmounts"
                 type="tel"
                 class="input-money -small"/>
        </el-form-item>
      </td>
      <td align="right" style="min-width: 130px;">
        <el-form-item class="mb-0">
          <input v-model.lazy="localProduct.final_price"
                 v-money="moneyMask"
                 @input="localUpdateAmounts"
                 type="tel"
                 class="input-money -small"/>
        </el-form-item>
      </td>
    </template>
    <template v-else>
      <td colspan="3">
        <strong>
          <template v-if="localProduct.coupon_id">O pagamento será feito utilizando saldo de créditos</template>
          <template v-else>Esse produto já está pago</template>
        </strong>
      </td>
    </template>
  </tr>
</template>

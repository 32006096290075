import {http as HTTP} from '../../http'

import {confirmDialog} from '@utils/flash'

const defaultPage = 1
const defaultPerPage = 50

/**
 * Função que processa a url para utilizar o padrão da API
 * @param domain - Rota do Domain da API para realizar a requisição Ex.: clients
 * @param route - Complemento de rota Ex.: clients/all
 * @param id - Id do registro
 * @param columns - Colunas do model que devem ser retornadas
 * @param relations - Relacionamentos do model que devem ser retornados
 * @param page - Página atual da paginação
 * @param perPage - Número de registros que devem ser retornados por página
 * @param deleted
 * @returns string
 */
function _processUrl(domain, route, id, columns, relations, page, perPage, deleted = false) {
    let url = domain

    if (id !== undefined && id !== null) {
        url += '/' + id
    }
    url += (route ? '/' + route : '')
    const queryParams = []

    // Inclui os dados da paginação na URL caso os parâmetros sejam passados
    if (page !== undefined && page !== null && page !== false) {
        page = page || defaultPage
        perPage = perPage || defaultPerPage
        queryParams.push(`_page=${page}`)
        queryParams.push(`_limit=${perPage}`)
    }

    // Inclui as colunas que devem retornar na requisição
    if (columns !== null && columns !== undefined && columns.length > 0) {
        columns.forEach(function (val) {
            if (val.length > 0) {
                queryParams.push(`_columns[]=${val}`)
            }
        })
    }

    // Inclui os relacionamentos que devem retornar na requisição
    if (relations !== null && relations !== undefined && relations.length > 0) {
        relations.forEach(function (val) {
            if (val.length > 0) {
                queryParams.push(`_with[]=${val}`)
            }
        })
    }
    if (deleted) {
        queryParams.push(`_deleted=true`)
    }
    return url + (!queryParams.length ? '' : queryParams
        .map((item, index) => (index === 0 ? '?' : '&') + item)
        .join(''));
}

/**
 * Função que faz uma requisição GET na API
 * @param domain - Rota do Domain da API para realizar a requisição Ex.: clients
 * @param route - Complemento de rota Ex.: clients/all
 * @param id - Id do registro
 * @param columns - Colunas do model que devem ser retornadas
 * @param relations - Relacionamentos do model que devem ser retornados
 * @param deleted
 * @returns Promise
 */
function _get(domain, route, id, columns, relations, deleted) {
    return HTTP.get(_processUrl(domain, route, id, columns, relations, null, null, deleted)).then(response => response.data)
}

/**
 * Função que faz uma requisição POST na API
 * @param domain - Rota do Domain da API para realizar a requisição Ex.: clients
 * @param route - Complemento de rota Ex.: clients/all
 * @param id - Id do registro
 * @param data - Dados do POST
 * @returns Promise
 */
function _post(domain, route, id, data) {
    return HTTP.post(_processUrl(domain, route, id), data).then(response => response.data)
}

/**
 * Função que faz uma requisição PUT na API
 * @param domain - Rota do Domain da API para realizar a requisição Ex.: clients
 * @param route - Complemento de rota Ex.: clients/all
 * @param id - Id do registro
 * @param data - Dados do POST
 * @returns Promise
 */
function _put(domain, route, id, data) {
    return HTTP.put(_processUrl(domain, route, (id || data.id)), data).then(response => response.data)
}

/**
 * Função que faz uma requisição POST/PUT na API
 * Caso existe o id no objeto data, a requisição sera PUT, caso não, será POST
 * @param domain - Rota do Domain da API para realizar a requisição Ex.: clients
 * @param route - Complemento de rota Ex.: clients/all
 * @param id - Id do registro
 * @param data - Dados do POST
 * @returns Promise
 */
function _save(domain, route, id, data) {
    if (data.id !== undefined && data.id !== null && data.id !== '' && data.id > 0) {
        return _put(domain, route, id, data)
    }
    return _post(domain, route, id, data)
}

/**
 * Função que faz uma requisição DELETE na API
 * @param domain
 * @param route
 * @param id
 * @returns Promise
 */
function _destroy(domain, route, id) {
    return new Promise(async (resolve) => {
        const {value: confirm} = await confirmDialog()
        if (confirm) {
            await HTTP.delete(_processUrl(domain, route, id))
            resolve()
        }
    })
}

/**
 * Busca todos os registros do domain
 * @param domain
 * @param route
 * @param id
 * @param columns
 * @param relations
 * @param page
 * @param perPage
 * @returns Promise
 */
function _all(domain, route, id, columns, relations, page, perPage) {
    return HTTP.get(_processUrl(domain, route, id, columns, relations, page, perPage)).then(response => response.data)
}

/**
 * Busca dinâmica de registros do domain
 * @param domain
 * @param route
 * @param data
 * @param columns
 * @param relations
 * @param page
 * @param perPage
 * @param customSearch
 * @returns Promise
 */
function _search(domain, route, data, columns, relations, page, perPage, customSearch) {
    if (data === undefined || data === null) {
        data = null
    }
    page = page || defaultPage
    perPage = perPage || defaultPerPage
    return HTTP.post(_processUrl(domain + (customSearch ? '' : '/search'), route, null, columns, relations, page, perPage), data).then(response => response.data)
}

export const get = _get
export const post = _post
export const put = _put
export const save = _save
export const destroy = _destroy
export const all = _all
export const search = _search

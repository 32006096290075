<script>
export default {
  name: 'finish-order-generate-nfce',
  props: ['generateNfce', 'includeCpf', 'client', 'isPaid', 'posGenerate', 'products'],
  data() {
    return {
      generateNfceLocal: this.generateNfce,
      includeCpfLocal: false,
    }
  },
  watch: {
    generateNfceLocal(value) {
      this.$emit('update:generateNfce', value)
    },
    includeCpfLocal(value) {
      this.$emit('update:includeCpf', value)
    }
  },
  computed: {
    clientCpf() {
      return this.client?.cpf_cnpj || this.client?.user?.cpf || null
    },
    generateNfceDisabled() {
      return !this.isPaid || !this.hasNfceProducts
    },
    hasNfceProducts() {
      return this.products.some(item => item.product.type === 'product' && item.product.nf_enabled)
    },
    paymentRequired() {
      return !this.isPaid
    },
  }
}
</script>

<template>
  <div>
    <div class="fs-14">Gerar Nota de Produto (NFCe)</div>
    <div>
      <el-switch v-model="generateNfceLocal"
                 :disabled="generateNfceDisabled || posGenerate"
                 :active-value="true"
                 active-text="SIM"
                 :inactive-value="false"
                 inactive-text="NÃO">
      </el-switch>
    </div>
    <div class="fs-14 mt-2">Identificar Cliente (CPF/CNPJ)</div>
    <div>
      <el-switch v-model="includeCpfLocal"
                 :disabled="!generateNfceLocal || !clientCpf"
                 :active-value="true"
                 active-text="SIM"
                 :inactive-value="false"
                 inactive-text="NÃO">
      </el-switch>
    </div>
    <el-alert type="warning" class="mt-2 small-alert text-center" :closable="false"
              v-if="paymentRequired || !clientCpf || !hasNfceProducts || posGenerate">
      <slot name="name">
        <div v-if="paymentRequired" class="font-weight-bold">Somente comandas pagas podem gerar nota de produto</div>
        <div v-if="!clientCpf">O cliente não possui CPF/CNPJ cadastrados para incluir na nota</div>
        <div v-if="!hasNfceProducts">A comanda não possui produtos habilitados para emissão de nota</div>
        <div v-if="posGenerate">A NFCe está vinculada ao pagamento na máquina POS e será gerada após finalizar a comanda</div>
      </slot>
    </el-alert>
  </div>
</template>

<script>
export default {
  name: 'status-label',
  props: ['status']
}
</script>

<template>
  <div>
    <el-tag type="primary" class="el-tag--status" v-if="status === 'active'">Ativo</el-tag>
    <el-tag type="danger" class="el-tag--status" v-if="status === 'inactive'">Inativo</el-tag>
    <el-tag type="primary" class="el-tag--status" v-if="status === 'created'">Criado</el-tag>
    <el-tag type="danger" class="el-tag--status" v-if="status === 'canceled'">Cancelado</el-tag>
    <el-tag type="danger" class="el-tag--status" v-if="status === 'disabled'">Desativado</el-tag>
    <el-tag type="danger" class="el-tag--status" v-if="status === 'expired'">Expirado</el-tag>
    <el-tag type="success" class="el-tag--status" v-if="status === 'paid'">Pago</el-tag>
  </div>
</template>

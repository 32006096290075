import localForage from 'localforage'

export const storage = localForage.createInstance({name: 'gripo-gestao'})

export const get = (item, defaultValue = null) => storage.getItem(item).then(v => v || defaultValue)

export const set = (item, value) => storage.setItem(item, value).then(() => value)

export const remove = (item) => storage.removeItem(item)

export const clear = () => storage.clear()

export default {
    get,
    set,
    remove,
    clear
}

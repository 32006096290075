<script>
export default {
  props: {
    id: {
      default: null
    },
    recurrent: {
      type: Boolean,
      default: false
    },
    payments: {
      type: Array
    },
    amount: {
      type: Number,
      default: 0
    },
    discount: {
      type: Number,
      default: 0
    },
    status: {
      type: String
    },
  },
  computed: {
    discountDiff() {
      if (this.recurrent && !this.id) {
        return 0
      }
      return this.amount - (this.payments || []).reduce((total, item) => total += item.amount, 0)
    },
    hasDiscount() {
      return (Array.isArray(this.payments) && this.payments.length && this.status === 'settled' && this.discountDiff > 0) || this.discount > 0
    },
  }
}
</script>

<template>
  <div class="currency-amount font-weight-bold" v-if="hasDiscount">
    (Desconto) {{ (discountDiff || discount) | currencyFormatter }}
  </div>
</template>

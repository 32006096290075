import * as restfulService from './restfulService'

const requestData = (listName, domain, route, data, columns, relations, page, perPage, useSearchAll = true) => {
    return {
        listName,
        domain,
        route,
        data,
        columns,
        relations,
        page,
        perPage,
        useSearchAll,
        list: []
    }
}

export default {
    async get(domains) {
        const promises = []
        const responses = {}
        if (domains !== undefined && domains.length > 0) {
            domains.forEach(function (val) {
                if (val !== undefined) {
                    val.route = val.route || null
                    let defaultData = {
                        orderBy: [
                            {
                                column: 'name',
                                direction: 'ASC'
                            }
                        ]
                    }
                    let data = val.data || defaultData
                    const columns = val.columns || null
                    const relations = val.relations || null
                    let list = []
                    switch (val.domain) {

                        // Remote data
                        case 'role':
                            promises.push(requestData('roles', 'role', val.route, data, columns, relations, 1, 2000))
                            responses.roles = []
                            break

                        case 'permission':
                            promises.push(requestData('permissions', 'permission', val.route, data, columns, relations, 1, 2000))
                            responses.permissions = []
                            break

                        case 'product_category':
                            data = {
                                orderBy: [
                                    {
                                        column: 'name',
                                        direction: 'ASC'
                                    }
                                ]
                            }
                            promises.push(requestData('product_categories', 'product_category', val.route, data, columns, relations, 1, 500))
                            responses.product_categories = []
                            break

                        case 'product':
                            data = {
                                orderBy: [
                                    {
                                        column: 'description',
                                        direction: 'ASC'
                                    }
                                ],
                                ...data,
                            }
                            promises.push(requestData('products', 'product', val.route, data, columns, ['category'], 1, 1000))
                            responses.products = []
                            break

                        case 'supplier':
                            data = {
                                orderBy: [
                                    {
                                        column: 'name',
                                        direction: 'ASC'
                                    }
                                ]
                            }
                            promises.push(requestData('suppliers', 'supplier', val.route, data, columns, relations, 1, 2000))
                            responses.suppliers = []
                            break

                        case 'client':
                            data = {
                                orderBy: [
                                    {
                                        column: 'name',
                                        direction: 'ASC'
                                    }
                                ]
                            }
                            promises.push(requestData('clients', 'client', val.route, data, columns, relations, 1, 2000))
                            responses.clients = []
                            break

                        case 'classification_expense':
                            data = {
                                type: 'expense',
                                orderBy: [
                                    {
                                        column: 'description',
                                        direction: 'ASC'
                                    }
                                ]
                            }
                            promises.push(requestData('classifications', 'entry_classification', val.route, data, columns, relations, 1, 500))
                            responses.classifications = []
                            break

                        case 'classification_revenue':
                            data = {
                                type: 'revenue',
                                orderBy: [
                                    {
                                        column: 'description',
                                        direction: 'ASC'
                                    }
                                ]
                            }
                            promises.push(requestData('classifications', 'entry_classification', val.route, data, columns, relations, 1, 500))
                            responses.classifications = []
                            break

                        case 'payment_method':
                            data = {
                                orderBy: [
                                    {
                                        column: 'order',
                                        direction: 'ASC'
                                    },
                                    {
                                        column: 'description',
                                        direction: 'ASC'
                                    }
                                ]
                            }
                            promises.push(requestData('payment_methods', 'entry_payment_method', val.route, data, columns, relations, 1, 500))
                            responses.payment_methods = []
                            break

                        case 'city':
                            promises.push(requestData('cities', 'city', val.route, data, null, ['state'], 1, 2000))
                            responses.cities = []
                            break

                        case 'state':
                            promises.push(requestData('states', 'state', val.route, data, columns, relations, 1, 2000))
                            responses.states = []
                            break

                        case 'court':
                            data = {
                                status: true,
                                orderBy: [
                                    {
                                        column: 'order',
                                        direction: 'ASC'
                                    },
                                    {
                                        column: 'name',
                                        direction: 'ASC'
                                    }
                                ]
                            }
                            promises.push(requestData('courts', 'court', val.route, data, columns, relations, 1, 2000))
                            responses.courts = []
                            break

                        case 'player_class':
                            data = {
                                orderBy: [
                                    {
                                        column: 'sex',
                                        direction: 'ASC'
                                    },
                                    {
                                        column: 'name',
                                        direction: 'ASC'
                                    }
                                ]
                            }
                            promises.push(requestData('players_class', 'player_class', val.route, data, columns, relations, 1, 2000))
                            responses.players_class = []
                            break

                        case 'circuit':
                            data = {
                                orderBy: [
                                    {
                                        column: 'name',
                                        direction: 'ASC'
                                    }
                                ],
                                ...data
                            }
                            promises.push(requestData('circuits', 'circuit', val.route, data, columns, relations, 1, 2000))
                            responses.circuits = []
                            break

                        case 'stage':
                            data = {
                                orderBy: [
                                    {
                                        column: 'name',
                                        direction: 'ASC'
                                    }
                                ],
                                ...data
                            }
                            promises.push(requestData('stages', 'stage', val.route, data, columns, relations, 1, 2000))
                            responses.stages = []
                            break

                        case 'stage_player_class':
                            promises.push(requestData('stage_players_class', 'stage_player_class', val.route, data, columns, relations, 1, 2000))
                            responses.stage_players_class = []
                            break

                        case 'stage_date':
                            promises.push(requestData('stage_dates', 'stage_date', val.route, data, columns, relations, 1, 2000))
                            responses.stage_dates = []
                            break

                        case 'club':
                            promises.push(requestData('clubs', 'club', val.route, data, columns, relations, 1, 2000))
                            responses.clubs = []
                            break

                        case 'player_group':
                            promises.push(requestData('players_group', 'player_group', val.route, data, columns, relations, 1, 2000))
                            responses.players_group = []
                            break

                        case 'stage_impediment':
                            data = {
                                orderBy: [
                                    {
                                        column: 'order',
                                        direction: 'ASC'
                                    }
                                ],
                                ...data
                            }
                            promises.push(requestData('stage_impediments', 'stage_impediment', val.route, data, columns, relations, 1, 2000))
                            responses.stage_impediments = []
                            break

                        case 'country':
                            promises.push(requestData('countries', 'country', val.route, data, columns, relations, 1, 2000))
                            responses.countries = []
                            break

                        case 'bank':
                            data = {
                                ...data, ...{
                                    orderBy: [
                                        {
                                            column: 'highlight',
                                            direction: 'DESC'
                                        },
                                        {
                                            column: 'name',
                                            direction: 'ASC'
                                        }
                                    ]
                                }
                            }
                            promises.push(requestData('banks', 'bank', val.route, data, columns, relations, 1, 2000))
                            responses.banks = []
                            break

                        case 'bank_account':
                            data = {
                                ...data, ...{
                                    status: 'active',
                                    orderBy: [
                                        {
                                            column: 'name',
                                            direction: 'ASC'
                                        }
                                    ]
                                }
                            }
                            promises.push(requestData('bank_accounts', 'bank_account', val.route, data, columns, relations, 1, 2000))
                            responses.bank_accounts = []
                            break

                        case 'product_tags':
                            promises.push(requestData('product_tags', 'product', 'tags', data, columns, relations, 1, 2000, false))
                            responses.product_tags = []
                            break

                        case 'plan':
                            data = {
                                ...data, ...{
                                    status: 'active',
                                    orderBy: [
                                        {
                                            column: 'name',
                                            direction: 'ASC'
                                        }
                                    ]
                                }
                            }
                            promises.push(requestData('plans', 'plan', val.route, data, columns, relations, 1, 2000))
                            responses.plans = []
                            break

                        case 'tenant_payment_account':
                            promises.push(requestData('tenant_payment_accounts', 'tenant', 'payment-accounts', data, columns, relations, 1, 2000, false))
                            responses.tenant_payment_accounts = []
                            break

                        case 'sport':
                            promises.push(requestData('sports', 'sport_type', 'search', data, columns, relations, 1, 2000, false))
                            responses.sports = []
                            break

                        // Static data
                        case 'portion':
                            list = []
                            for (let i = 2; i <= 48; i++) {
                                list.push({
                                    id: i,
                                    value: i
                                })
                            }
                            promises.push({listName: 'portions', list})
                            responses.portions = []
                            break

                        case 'interval_type':
                            list = []
                            list.push({id: 'days', name: 'Dia(s)'})
                            list.push({id: 'months', name: 'Mês(es)'})
                            list.push({id: 'years', name: 'Ano(s)'})
                            promises.push({listName: 'interval_types', list})
                            responses.interval_types = []
                            break

                        case 'entry_status':
                            list = [
                                {id: 'created', name: 'Aberto'},
                                {id: 'settled', name: 'Quitado'}
                            ]
                            promises.push({listName: 'entry_status', list})
                            responses.entry_status = []
                            break

                        case 'stage_status':
                            list = [
                                {id: 'all', name: 'Não filtrar'},
                                {id: 'active', name: 'Ativo'},
                                {id: 'discarded', name: 'Descartado'},
                                {id: 'finished', name: 'Finalizado'},
                                {id: 'inactive', name: 'Inativo'},
                            ]
                            promises.push({listName: 'stage_status', list})
                            responses.stage_status = []
                            break

                        case 'hour':
                            list = []
                            for (let i = 5; i < 24; i++) {
                                list.push({
                                    id: i.toString().padStart(2, '0') + ':00',
                                    name: i.toString().padStart(2, '0') + ':00'
                                })
                            }
                            list.push({
                                id: '00:00',
                                name: '00:00'
                            })
                            promises.push({listName: 'hours', list})
                            responses.hours = []
                            break

                        case 'hour_interval':
                            list = []
                            for (let i = 5; i < 24; i++) {
                                list.push({
                                    id: i.toString().padStart(2, '0') + ':00',
                                    name: i.toString().padStart(2, '0') + ':00'
                                })
                                list.push({
                                    id: i.toString().padStart(2, '0') + ':30',
                                    name: i.toString().padStart(2, '0') + ':30'
                                })
                            }
                            list.push({
                                id: '00:00',
                                name: '00:00'
                            })
                            promises.push({listName: 'hours_interval', list})
                            responses.hours_interval = []
                            break

                        case 'match_type':
                            list = []
                            list.push({round: 0, value: 'Chave', points: '', winner_points: null})
                            list.push({round: 64, value: 'Trinta e dois avos', points: '', winner_points: null})
                            list.push({round: 32, value: 'Dezesseis avos', points: '', winner_points: null})
                            list.push({round: 16, value: 'Oitavas', points: '', winner_points: null})
                            list.push({round: 8, value: 'Quartas', points: '', winner_points: null})
                            list.push({round: 4, value: 'Semifinal', points: '', winner_points: null})
                            list.push({round: 2, value: 'Final', points: '', winner_points: ''})
                            promises.push({listName: 'match_types', list})
                            responses.match_types = []
                            break

                        case 'registration_status':
                            list = [
                                {id: '', name: 'Não filtrar'},
                                {id: 'created', name: 'Pendente'},
                                {id: 'approved', name: 'Confirmada'},
                                {id: 'canceled', name: 'Cancelada'},
                            ]
                            promises.push({listName: 'registration_status', list})
                            responses.registration_status = []
                            break

                        case 'transaction_status':
                            list = [
                                {id: '', name: 'Não filtrar'},
                                {id: 'created', name: 'Aguardando Pgto.'},
                                {id: 'canceled', name: 'Cancelado'},
                                {id: 'refunded', name: 'Estornado'},
                                {id: 'paid', name: 'Pago'}
                            ]
                            promises.push({listName: 'transaction_status', list})
                            responses.transaction_status = []
                            break

                        case 'court_type':
                            list = [
                                {id: 'cement', name: 'Alvenaria'},
                                {id: 'sand', name: 'Areia'},
                                {id: 'grass', name: 'Grama'},
                                {id: 'clay', name: 'Saibro'},
                                {id: 'glass', name: 'Vidro'},
                            ]
                            promises.push({listName: 'court_types', list})
                            responses.court_types = []
                            break

                        case 'court_place':
                            list = [
                                {id: 'opened', name: 'Aberta'},
                                {id: 'covered', name: 'Coberta'}
                            ]
                            promises.push({listName: 'court_places', list})
                            responses.court_places = []
                            break

                        case 'court_interval':
                            list = [
                                {id: 15, value: '00:15'},
                                {id: 30, value: '00:30'},
                                {id: 45, value: '00:45'},
                                {id: 60, value: '01:00'},
                                {id: 75, value: '01:15'},
                                {id: 90, value: '01:30'},
                                {id: 105, value: '01:45'},
                                {id: 120, value: '02:00'},
                                {id: 135, value: '02:15'},
                                {id: 150, value: '02:30'},
                                {id: 165, value: '02:45'},
                                {id: 180, value: '03:00'}
                            ]
                            promises.push({listName: 'court_intervals', list})
                            responses.court_intervals = []
                            break

                        case 'court_date':
                            list = [
                                {id: 'weekday', name: 'Seg à Sex', interval: '', start_time: '', end_time: ''},
                                {id: 'saturday', name: 'Sábado', interval: '', start_time: '', end_time: ''},
                                {id: 'sunday', name: 'Domingo', interval: '', start_time: '', end_time: ''}
                            ]
                            promises.push({listName: 'court_dates', list})
                            responses.court_dates = []
                            break

                        case 'weekday':
                            list = [
                                {id: 1, name: 'Segunda'},
                                {id: 2, name: 'Terça'},
                                {id: 3, name: 'Quarta'},
                                {id: 4, name: 'Quinta'},
                                {id: 5, name: 'Sexta'},
                                {id: 6, name: 'Sábado'},
                                {id: 0, name: 'Domingo'}
                            ]
                            promises.push({listName: 'weekdays', list})
                            responses.weekdays = []
                            break

                        case 'nf_unidade_comercial':
                            list = [
                                {id: 'KG', name: 'KG'},
                                {id: 'L', name: 'L'},
                                {id: 'UN', name: 'UN'}
                            ]
                            promises.push({listName: 'nf_unidade_comercial', list})
                            responses.nf_unidade_comercial = []
                            break

                        case 'nf_icms_origem':
                            list = [
                                {id: '0', name: 'Nacional'},
                                {id: '1', name: 'Estrangeira (importação direta)'},
                                {id: '2', name: 'Estrangeira (adquirida no mercado interno)'},
                                {id: '3', name: 'Nacional com mais de 40% de conteúdo estrangeiro'},
                                {id: '4', name: 'Nacional produzida através de processos produtivos básicos'},
                                {id: '5', name: 'Nacional com menos de 40% de conteúdo estrangeiro'},
                                {id: '6', name: 'Estrangeira (importação direta) sem produto nacional similar'},
                                {id: '7', name: 'Estrangeira (adquirida no mercado interno) sem produto nacional similar'}
                            ]
                            promises.push({listName: 'nf_icms_origem', list})
                            responses.nf_icms_origem = []
                            break

                        case 'nf_icms_situacao_tributaria':
                            list = [
                                {id: '00', name: 'Tributada integralmente', simples_nacional: false},
                                {id: '40', name: 'Isenta', simples_nacional: false},
                                {id: '41', name: 'Não tributada', simples_nacional: false},
                                {
                                    id: '60',
                                    name: 'ICMS cobrado anteriormente por substituição tributária',
                                    simples_nacional: false
                                },
                                {
                                    id: '101',
                                    name: 'Tributada pelo Simples Nacional com permissão de crédito',
                                    simples_nacional: true
                                },
                                {
                                    id: '102',
                                    name: 'Tributada pelo Simples Nacional sem permissão de crédito',
                                    simples_nacional: true
                                },
                                {
                                    id: '103',
                                    name: 'Isenção do ICMS no Simples Nacional para faixa de receita bruta',
                                    simples_nacional: true
                                },
                                {id: '300', name: 'Imune', simples_nacional: true},
                                {
                                    id: '500',
                                    name: 'CMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação',
                                    simples_nacional: true
                                },
                                {
                                    id: '900',
                                    name: 'Outros'
                                },
                            ]
                            promises.push({listName: 'nf_icms_situacao_tributaria', list})
                            responses.nf_icms_situacao_tributaria = []
                            break

                        case 'nf_forma_pagamento':
                            list = [
                                {id: '01', name: 'Dinheiro'},
                                {id: '02', name: 'Cheque'},
                                {id: '03', name: 'Cartão de Crédito'},
                                {id: '04', name: 'Cartão de Débito'},
                                {id: '05', name: 'Crédito Loja'},
                                {id: '10', name: 'Vale Alimentação'},
                                {id: '11', name: 'Vale Refeição'},
                                {id: '12', name: 'Vale Presente'},
                                {id: '13', name: 'Vale Combustível'},
                                {id: '99', name: 'Outros'},
                            ]
                            promises.push({listName: 'nf_forma_pagamento', list})
                            responses.nf_forma_pagamento = []
                            break

                        case 'nf_regime_tributario':
                            list = [
                                {id: 1, name: 'Simples Nacional'},
                                {id: 2, name: 'Simples Nacional - Excesso de sublimite de receita bruta'},
                                {id: 3, name: 'Regime Normal'},
                            ]
                            promises.push({listName: 'nf_regime_tributario', list})
                            responses.nf_regime_tributario = []
                            break

                        case 'nf_regime_especial_tributacao':
                            list = [
                                {id: 1, name: 'Microempresa'},
                                {id: 2, name: 'Estimativa'},
                                {id: 3, name: 'Sociedade de profissionais'},
                                {id: 4, name: 'Cooperativa'},
                                {id: 5, name: 'MEI - Simples Nacional'},
                                {id: 6, name: 'ME EPP- Simples Nacional'},
                            ]
                            promises.push({listName: 'nf_regime_especial_tributacao', list})
                            responses.nf_regime_especial_tributacao = []
                            break

                        case 'shirt_type':
                            list.splice(0)
                            list.push({id: 'standard', name: 'Normal'})
                            list.push({id: 'tight', name: 'Baby Look'})
                            promises.push({listName: 'shirt_type', list})
                            responses.shirt_type = []
                            break

                        case 'shirt_size':
                            list.splice(0)
                            list.push({id: 'P', name: 'P'})
                            list.push({id: 'M', name: 'M'})
                            list.push({id: 'G', name: 'G'})
                            list.push({id: 'GG', name: 'GG'})
                            list.push({id: 'EG', name: 'EG'})
                            list.push({id: 'EGG', name: 'EGG'})
                            promises.push({listName: 'shirt_size', list})
                            responses.shirt_size = []
                            break

                        case 'circle_bg_color':
                            list.splice(0)
                            list.push({id: '#014684', name: 'Cor 1'})
                            list.push({id: '#4A6127', name: 'Cor 2'})
                            list.push({id: '#E1581D', name: 'Cor 3'})
                            list.push({id: '#333333', name: 'Cor 4'})
                            list.push({id: '#544667', name: 'Cor 5'})
                            list.push({id: '#b35c61', name: 'Cor 6'})
                            list.push({id: '#00A450', name: 'Cor 7'})
                            list.push({id: '#EC165C', name: 'Cor 8'})
                            list.push({id: '#92268F', name: 'Cor 9'})
                            promises.push({listName: 'circle_bg_color', list})
                            responses.circle_bg_color = []
                            break

                        case 'stage_bracket_system':
                            list.splice(0)
                            list.push({id: 'common', name: 'Padrão'})
                            list.push({id: 'cobrapa', name: 'Cobrapa'})
                            list.push({id: 'super16', name: 'Super16'})
                            promises.push({listName: 'stage_bracket_system', list})
                            responses.stage_bracket_system = []
                            break

                        case 'default_plans':
                            list = [
                                {id: 'members_stage', name: 'Sócios/Afiliados Torneios'},
                                {id: 'schedule_fixed', name: 'Horário Fixo'},
                                {id: 'schedule_credit', name: 'Horário Avulso'},
                                {id: 'lesson_fixed', name: 'Aula Fixa'},
                                {id: 'lesson_single', name: 'Aula Avulsa'},
                                {id: 'lesson_pack', name: 'Pacote de Aulas'},
                                {id: 'custom', name: 'Customizado'},
                            ]
                            promises.push({listName: 'default_plans', list})
                            responses.default_plans = []
                            break

                        case 'plan_status':
                            list = [
                                {id: 'created', name: 'Criado'},
                                {id: 'active', name: 'Ativo'},
                                {id: 'canceled', name: 'Cancelado'},
                                {id: 'disabled', name: 'Desativado'},
                            ]
                            promises.push({listName: 'plan_status', list})
                            responses.plan_status = []
                            break

                        case 'plan_rule_status':
                            list = [
                                {id: 'created', name: 'Pendente'},
                                {id: 'active', name: 'Ativo'},
                                {id: 'expired', name: 'Expirado'},
                                {id: 'canceled', name: 'Cancelado'},
                            ]
                            promises.push({listName: 'plan_rule_status', list})
                            responses.plan_rule_status = []
                            break

                        case 'plan_rule_type':
                            list = [
                                {id: 'percent', name: 'Desconto %'},
                                {id: 'amount', name: 'Desconto'},
                                {id: 'credit', name: 'Créditos'},
                            ]
                            promises.push({listName: 'plan_rule_types', list})
                            responses.plan_rule_types = []
                            break

                        case 'plan_rule_target':
                            list = [
                                {id: 'product', name: 'Produtos'},
                                {id: 'registration', name: 'Inscrições'},
                                {id: 'schedule', name: 'Reservas'},
                                {id: 'lesson', name: 'Aulas'},
                            ]
                            promises.push({listName: 'plan_rule_targets', list})
                            responses.plan_rule_targets = []
                            break

                        case 'schedule_type':
                            list = [
                                {id: 'schedule', name: 'Horário Avulso'},
                                {id: 'schedule-fixed', name: 'Horário Fixo'},
                                {id: 'lesson', name: 'Aula Avulsa'},
                                {id: 'lesson-fixed', name: 'Aula Fixa'},
                                {id: 'app', name: 'Online'},
                            ]
                            promises.push({listName: 'schedule_types', list})
                            responses.schedule_types = []
                            break

                        case 'schedule_status':
                            list = [
                                {id: 'created', name: 'Aguardando Confirmação'},
                                {id: 'scheduled', name: 'Confirmada'},
                                {id: 'released', name: 'Fixo Liberada'},
                                {id: 'canceled', name: 'Cancelada'},
                            ]
                            promises.push({listName: 'schedule_status', list})
                            responses.schedule_status = []
                            break

                        case 'stage_type':
                            list.splice(0)
                            list.push({id: 'padel', name: 'Padel'})
                            list.push({id: 'tennis', name: 'Tênis'})
                            list.push({id: 'beach_tennis', name: 'Beach Tennis'})
                            list.push({id: 'other', name: 'Outros'})
                            promises.push({listName: 'stage_types', list})
                            responses.stage_types = []
                            break

                        case 'coupon_discount_type':
                            list = [
                                {id: 'amount', description: 'Valor de desconto'},
                                {id: 'amount_replace', description: 'Substituir o valor total pelo desconto'},
                                //{id: 'percent', description: 'Percentual de desconto sobre o valor'},
                            ]
                            promises.push({listName: 'coupon_discount_types', list})
                            responses.coupon_discount_types = []
                            break

                    }
                }
            })
        }
        return new Promise(async (resolve) => {
            promises.filter(item => item.list.length > 0).map(item => {
                if (responses[item.listName] !== undefined) {
                    responses[item.listName] = [...item.list]
                }
            })

            const searchAll = promises.filter(item => item.list.length === 0 && item.useSearchAll === true)
            if (searchAll.length > 0) {
                const searchDomains = searchAll
                    .map(({
                              domain,
                              route,
                              data,
                              columns,
                              relations,
                              page,
                              perPage,
                              useSearchAll
                          }) => {
                        return {
                            domain,
                            route,
                            data,
                            columns,
                            relations,
                            page,
                            perPage,
                            useSearchAll,
                        }
                    })
                const result = await restfulService.post('search-all', null, null, {domains: searchDomains})
                searchAll.map(item => {
                    if (responses[item.listName] !== undefined && result[item.domain] !== undefined) {
                        responses[item.listName] = [...result[item.domain]]
                    }
                })
            }
            const commonCall = promises.filter(item => item.list.length === 0 && !item.useSearchAll)
            if (commonCall.length > 0) {
                for (const index in commonCall) {
                    const item = commonCall[index]
                    const result = await restfulService.post(item.domain, item.route, null, item.data)
                    if (responses[item.listName] !== undefined) {
                        responses[item.listName] = [...result]
                    }
                }
            }
            resolve(responses)
        })
    }
}
